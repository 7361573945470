import config from "../config";

export default {
	data () {
		return {
			callConfigs: {
				getParticipationTypeRef: {
					title: "GET Participation Type Ref.",
					url: `/dropdown/participation-type-reference`,
					cbSuccess: this.cb_participationTypeRefFound,
					cbError: this.cb_participationTypeRefNotFound
				},
				getCouponsDetails: {
					title: "GET coupons details",
					url: `/dropdown/${config.idlead}/leadcoupons`,
					cbSuccess: this.whatIfCouponsDetailsAreObtained,
					cbError: this.whatIfCouponsDetailsAreNotObtained
				},
				getSynonyms: {
					title: "GET Synonyms",
					url: `/dropdown/${config.idlead}/items-synonym`,
					cbSuccess: this.whatIfIGetTheSynonymsList,
					cbError: this.whatIfICantGetTheSynonymsList
				},
				getFingerprint: {
					title: 'GET getFingerprint',
					url: `/fingerprint/${config.idlead}`,
					cbSuccess: this.whatIfIGotTheFingerPrint,
					cbError: this.whatIfIDontGetTheFingerprint
				},
				getLocalizedData : {
					title: 'GET localizing data',
					url: `/dropdown/${config.idlead}/languages-code`,
					cbSuccess: this.localizingDataFound, 
					cbError: this.localizingDataNotFound
				},
				getLead : {                    
					title: 'GET Lead',
					url: `/leads/${config.idlead}`,
					cbSuccess: this.whatIfIGetTheLead, 
					cbError: this.whatIfIDontGetTheLead,
				},
				getProductsIds: {
					title: 'GET Products IDs',
					url: `/dropdown/${config.idlead}/popitems`,
					cbSuccess: this.whatIfIFoundProductsIds, 
					cbError: this.whatIfICantFoundProductId
				},
				getParticipationOwnerData: {
					title: 'GET participationOwner',
					url: `/participationOwner/participation/`,
					cbSuccess: this.whatIfIGetTheParticipationOwnerData,
					cbError: this.whatIfIDontGetTheParticipationOwnerData
				},
				getOwnerData: {
					title: 'GET OwnerData',
					url: `/participationOwner/`,
					cbSuccess: this.whatIfIGetTheOwnerData, 
					cbError: this.whatIfIDontGetTheOwnerData
				},
				getMetasIds: {
					title: 'GET Fields',
					url: `/lead/${config.idlead}/fields`,
					cbSuccess: this.whatIfIGetTheFields, 
					cbError: this.whatIfIDontGetTheFields
				},
				getCountries: {
					title: "GET allowed Countries",
					url: `/dropdown/${config.idlead}/countries-allowed`,
					cbSuccess: this.whatIfIGetTheCountries,
					cbError: this.whatIfICantGetTheCountries
				},
				getRetailers: {
					title: "GET Retailers list",
					url: `/dropdown/${config.idlead}/retailers`,
					cbSuccess: this.whatIfIGetTheRetailersList,
					cbError: this.whatIfIDontGetTheRetailersList
				},
				postParticipationOwner: {
					title: 'POST Participation Owner',
					url: `/participationOwner`,
					cbSuccess: this.successInCreateParticipationOwner,
					cbError: this.failedInCreateParticipationOwner
				},
				postParticipation: {
					title: 'POST Participation',
					url: `participation`,
					cbSuccess: this.participationSuccessful,
					cbError: this.participationFailed,
				}

			}
		}
	},
	methods: {

		/* One function to call them all */
		getFromAxios(call, arg=''){
			this.log(`${call.title}`, 'api');
			this.log(call);
			if(arg.length>0){this.log(`Argument received to add at the end of API call url: ${arg}`, 'api');}
			this.axios.get(call.url + arg)
			.then(res => {
				this.log(`API call "${call.title}" is a success`, 'success');
				this.log(res.data);
				call.cbSuccess(res.data);
			})
			.catch(error => {
				this.log(`Axios "${call.title}" function`, 'error');
				this.log(error);
				this.checkErrorCodeIfAny(call, error);			
			})
		},


		checkErrorCodeIfAny(call, error){

			switch(error.response.status){
			case 422 : 
				this.displayMessage(`${error.response.status} - ${error.response.statusText}`, ["Cannot get the data for this action ID"], 'alert');
				break;
			case 503 :
				this.displayMessage(`${error.response.status} - ${error.response.statusText}`, ["Maintenance in progress"], 'alert');
				this.currentPage = "maintenance"; /* TODO: test if it works "LIVE" */
				/* WIP IT DOESN'T WORK YET, redirection don't take place, because 1- I dont receive error 503 2- I cannot simulate them (yet) */
				break;
			default:
				call.cbError(error);
			}

		},


		postToAxios(call, data){
			this.log(`Sending this information to actionAPI`);
			this.log(data);
			this.axios.post(call.url, data)
			.then(res => {
				this.log(`Success in posting ${call.title} data`,'success');
				this.log(res);	
				call.cbSuccess(res);
			})
			.catch(error => {
				this.log(`Failed attempt to post for ${call.title}`, 'alert');
				console.log(error);
				call.cbError(error);
			})
		},



	},
}